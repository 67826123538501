<template>
  <v-card class="pa-2">
    <v-form ref="form" @submit.prevent="save">
      <v-row>
        <v-col cols="12">
          <v-select
            filled 
            clearable
            hide-details="auto" 
            v-model="escandallo.idArticulo"
            label="Artículo (Búsqueda de Artículo/Producto creado)"
            :items="articulos"
            :item-text="item => item.nombre" 
            :item-value="item => item.idArticulo" 
            class="v-superdense-input"
            :rules="[rules.req]"
            append-icon="mdi-food-turkey"
            ></v-select>
            <!-- @change="$set(escandallo, 'nombre', (escandallo.idArticulo ? (articulos.filter(art => art.idArticulo == escandallo.idArticulo)[0].nombre) : ''));escandallo.formula ? '' : $set(escandallo,'formula','p')" -->
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="escandallo.nombre"
            class="v-superdense-input"
            label="Escandallo / Ración"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select
            filled 
            clearable
            multiple
            hide-details="auto" 
            :rules="[rules.req]"
            v-model="escandallo.familias" 
            label="Familia(s)"
            :items="familias" 
            :item-text="item => item.nombre" 
            :item-value="item => item.idFamilia" 
            class="v-superdense-input"
            append-icon="mdi-graph-outline"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="escandallo.formula"
            label="Fórmula (por ejemplo: p*0.350/2)"
            append-icon="mdi-help"
            @click:append="showHelp"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            :value="escandallo.formula && escandallo.idArticulo && precio ? precio.toFixed(2) : ''"
            label="Precio (calculado por fórmula)"
            append-icon="mdi-currency-eur"
            disabled
            ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            filled
            hide-details="auto"
            v-model="escandallo.nota"
            label="Descripción del escandallo"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-card-actions class="d-flex">
						<v-spacer></v-spacer>
						<v-btn v-if="escandallo.idEscandallo" @click.stop="escandallo = {}">
							<v-icon left>mdi-close</v-icon>
												Cancelar
						</v-btn>
						<v-btn @click.stop="save" color="primary">
							<v-icon left>mdi-content-save</v-icon>
							{{ escandallo.idEscandallo ? "Actualizar" : "Crear" }}
						</v-btn>
					</v-card-actions>
        </v-col>
      </v-row>
    </v-form>

    <ShowHelp ref="help"></ShowHelp>
  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";

export default {
  props: {
    value: Object,
    familias: Array,
    articulos: Array
  },
  components:{
    ShowHelp: () => import("./ShowHelp.vue"),
  },
  data() {
    return {
      default: {
        nombre : null,
      },
      escandallo: {
        ...this.value,
      },
      rules: {
        req,
      },
    };
  },
  computed:{
    precio(){
      let bad = false
      let allowed = " p1234567890/()+-*."
      let p = this.articulos.filter(art => art.idArticulo == this.escandallo.idArticulo)[0].precio
      this.escandallo.formula = this.escandallo.formula.toLowerCase()
      this.escandallo.formula.split('').forEach(char => {
        if (allowed.indexOf(char) == -1) 
        {
          this.$root.$emit("snack", "Carácteres válidos: <p123456789()+-*/. >");
          bad = true
        }
      });
      if (!bad){
        this.escandallo.formula = this.escandallo.formula.replaceAll(' ', '')
        this.escandallo.formula = this.escandallo.formula.replaceAll(")0", ")*0");
        this.escandallo.formula = this.escandallo.formula.replaceAll(")1", ")*1");
        this.escandallo.formula = this.escandallo.formula.replaceAll(")2", ")*2");
        this.escandallo.formula = this.escandallo.formula.replaceAll(")3", ")*3");
        this.escandallo.formula = this.escandallo.formula.replaceAll(")4", ")*4");
        this.escandallo.formula = this.escandallo.formula.replaceAll(")5", ")*5");
        this.escandallo.formula = this.escandallo.formula.replaceAll(")6", ")*6");
        this.escandallo.formula = this.escandallo.formula.replaceAll(")7", ")*7");
        this.escandallo.formula = this.escandallo.formula.replaceAll(")8", ")*8");
        this.escandallo.formula = this.escandallo.formula.replaceAll(")9", ")*9");
        this.escandallo.formula = this.escandallo.formula.replaceAll(")p", ")*p");
                    // mismo numero de parentesis ()
        if (((this.escandallo.formula.match(/\(/g) || []).length == (this.escandallo.formula.match(/\)/g) || []).length) 
                    // no hay () vacíos
        && ((this.escandallo.formula.match(/\(\)/g)|| []).length == 0) 
                    // no hay un * seguido de nada
        && ((this.escandallo.formula.match(/[0-9p\()\.]\*$/g)|| []).length == 0) 
                    // no hay un * precedido de nada
        && ((this.escandallo.formula.match(/^\*[0-9p\()\.]/g)|| []).length == 0) 
                      // no hay un * solo
        && ((this.escandallo.formula.match(/^\*$/g)|| []).length == 0) 
                      // no hay un / seguido de nada
        && ((this.escandallo.formula.match(/[0-9p\()\.]\/$/g)|| []).length == 0) 
                      // no hay un / precedido de nada
        && ((this.escandallo.formula.match(/^\/[0-9p\()\.]/g)|| []).length == 0) 
                      // no hay un / solo
        && ((this.escandallo.formula.match(/^\/$/g)|| []).length == 0) 
                      // no hay un /0
        // && ((this.escandallo.formula.match(/\/(?![0]*\.(?![0]*[1-9]))/g)|| []).length > 0)
        // && ((this.escandallo.formula.match(/\/(?![p]*\.(?![p]*[1-9]))/g)|| []).length > 0 && p == 0)
        //               // no hay un /. o /.0
        // && ((this.escandallo.formula.match(/\/(?!\.[1-9])/g)|| []).length == 0)
        /*
          // un /.p sin un numero mayor que 0 detrás
          \/\.p(?![1-9])

          // un /.p o /.0
          \/\.[p0]

        */
        //                     // no hay un /0.  /0.0  o  /0.p siendo p = 0                                    // no hay un /.0  o  /.p siendo p = 0                                     // no hay un /p o /p.0 siendo p = 0
        // && !(p == 0 && ((this.escandallo.formula.match(/\/0(?!\.[1-9p])/g)|| []).length > 0 || (this.escandallo.formula.match(/\/(?!\.[1-9p])/g)|| []).length > 0) || (this.escandallo.formula.match(/\/p(?!\.[1-9])/g)|| []).length > 0)
        )
        try{
          return eval(this.escandallo.formula.replaceAll('p', p))
        }
        catch (e){
          return 0
        }
      }
      else{
        return 0
      }
    }
  },
  methods: {
    showHelp(){
      this.$refs.help.open()
    },
    async save() {
      if (!this.$refs.form.validate()) return;
      this.escandallo.actualizado = new Date(new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate() + ' 12:00:00').toISOString().split('T')[0]
      await axios({
        method: this.escandallo.idEscandallo ? "PUT" : "POST",
        url: `/escandallos/${String(
          this.escandallo.idEscandallo ? this.escandallo.idEscandallo : ""
        )}`.replace(/\/+$/, ''),
        data: {
          ...this.escandallo,
        },
      });
      this.escandallo = {}
      this.$emit("reload");
    },
  },
};
</script>

<style>
</style>